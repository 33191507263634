// root.tsx
import type { FC } from "react";
import React, { useEffect, useState } from "react";

import type { StyleProps } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";

import { useGlobalContext } from "~/context/global";

export const RenderHotmobDesktop: FC<
  StyleProps & {
    hotmobId?: string;
    hotmobIndex?: string;
    style?: React.CSSProperties;
    sourceCode?: string;
    children: React.ReactNode;
  }
> = ({ hotmobId, hotmobIndex, style, sourceCode, children, ...flexProps }) => {
  const { isDesktop } = useGlobalContext();

  const [isHydrated, setIsHydrated] = useState(false);

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  if (!isHydrated) return null;

  if (isDesktop && !!children) {
    return (
      <Box className="renderHotmobDesktop" overflow={"hidden"} {...flexProps}>
        {children}
      </Box>
    );
  }

  return <></>;
};
